import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useCallFetchv2 } from 'helpers/callFetchv2';
import { useTranslation } from 'react-i18next';
import SoftSnackbar from "components/SoftSnackbar";
const WireHouseCreate = () => {
    const params = useParams();
    const callFetch = useCallFetchv2();
    const { t } = useTranslation();
    const [lagerNumber, setLagerNumber] = useState(0);
    const [data, setData] = useState({});
    const [employees, setEmployees] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (!params?.id) return;
        callFetch("lager/" + params?.id + "/edit", "GET", []).then((res) => {
            setData(res.data);
        });
    }, [params?.id]);

    useEffect(() => {
        if (!data?.id) return;
        for (let [key, value] of Object.entries(data)) {
            setValue(key, (value == null || value == 'null' ? "" : value));
        }
    }, [data?.id, employees.length]);

    useEffect(() => {
        callFetch("lager/create", "GET", []).then((res) => {
            setEmployees(res?.employees);
            if (!params?.id){
                setValue('lager_nr', res?.lager_nr)
            }            
        });
    }, [params?.id]);

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Successfully updated"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );




    const onSubmit = (formData) => {
        setSaving(true);
        callFetch(params?.id ? "lager/" + params?.id : "lager", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            /*
            if(res.message === 'Successfully updated' && params?.id){
                openSuccessSB();
            }
                */
            setLagerNumber(res.lager_nr);
            setSubmitSuccess(true);
        });
    };

    return submitSuccess && params?.id > 0 === false ? <Navigate to={'/ware-houses/' + lagerNumber + '/edit'} /> :
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Neuer Lager')}</h6>
                        </div>
                        <div className="card-body">
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                {
                                    params?.id && <input type="hidden" defaultValue="PUT" {...register("_method")} />
                                }
                                <div className="row g-3">

                                    <div className="col-md-4">
                                        <label>{t('Lager Nr.')} *</label>
                                        <input type="text" className="form-control mb-4" {...register('lager_nr', { required: true })} required readOnly />
                                        <div className="invalid-feedback">{errors.lager_nr && errors.lager_nr.message}</div>
                                    </div>
                                </div>

                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <label>{t('Lagername')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("lager_name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Verantwortliche Person')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("verantwortliche_person", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value="">{t('----')}</option>
                                            {employees && employees?.map((employee, index) => (
                                                <option key={employee.id + index} value={employee.user?.id}>{employee.user?.name}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.verantwortliche_person && errors.verantwortliche_person.message}</div>
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Größe und Kapazität')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("grobe_und_kapazitat", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.grobe_und_kapazitat && errors.grobe_und_kapazitat.message}</div>
                                    </div>

                                    <div className="row g-2">
                                        <div className="col-md-12">
                                            <label>{t('Addresse')} *</label>
                                            <input
                                                type="text"
                                                className="form-control mb-4"
                                                placeholder={t('')}
                                                {...register("lager_address", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.lager_address && errors.lager_address.message}</div>
                                        </div>
                                    </div>

                                    <div className="row g-2">
                                        <div className="col-md-4">
                                            <label>{t('Land')} *</label>
                                            <input
                                                type="text"
                                                className="form-control mb-4"
                                                placeholder={t('')}
                                                {...register("lager_country", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.lager_country && errors.lager_country.message}</div>
                                        </div>
                                        <div className="col-md-4">
                                            <label>{t('Stadt')} *</label>
                                            <input
                                                type="text"
                                                className="form-control mb-4"
                                                placeholder={t('')}
                                                {...register("lager_city", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.lager_city && errors.lager_city.message}</div>
                                        </div>
                                        <div className="col-md-4">
                                            <label>{t('PLZ')} *</label>
                                            <input
                                                type="text"
                                                className="form-control mb-4"
                                                placeholder={t('')}
                                                {...register("lager_plz", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.lager_plz && errors.lager_plz.message}</div>
                                        </div>
                                    </div>

                                    <div className="row g-2">
                                        <div className="col-md-12">
                                            <label>{t('Lagerbeschreibung')}</label>
                                            <textarea
                                                className="form-control"
                                                rows="4"
                                                placeholder={t('')}
                                                {...register("description")}></textarea>
                                            <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-4">
                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>;
            {renderSuccessSB}
        </>
}

export default WireHouseCreate