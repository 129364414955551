import { React, useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import Message from '../pages/chat/Message';
import callFetch from "../helpers/callFetch";
import { t } from "i18next";
import LastMessage from "pages/chat/LastMessage";
import Moment from "react-moment";
import moment from 'moment';
import 'moment/locale/de'; // Import the German locale
import { Menu, MenuItem } from "@mui/material";
import Breadcrumbs from "examples/Breadcrumbs";
import SoftBox from "components/SoftBox";
import { margin, width } from "@mui/system";

moment.locale('de'); // Set the locale globally to German

function TopNav({ absolute, light, isMini }) {
    const [user, setUser] = useState(JSON.parse(Cookies.get('user')));
    const [customClass, setCustomClass] = useState('');
    const [participations, setParticipations] = useState([]);
    const [unreadMessage, setunreadMessage] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [unreadNotification, setUnreadNotification] = useState(0);
    const [searchData, setSearchData] = useState([])
    const [searchDataMobile, setSearchDataMobile] = useState([])
    const [searchKey, setSearchKey] = useState(0)
    const [searchKeyMobile, setSearchKeyMobile] = useState(0)
    const [menu, setMenu] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showClose, setShowClose] = useState(false);
    const [menuMobile, setMenuMobile] = useState(false);
    const [showNotFound, setShowNotFound] = useState(false);
    const [loadingMobile, setLoadingMobile] = useState(false);
    const [showCloseMobile, setShowCloseMobile] = useState(false);

    const route = useLocation().pathname.split("/").slice(1);

    const [query, setQuery] = useState('');
    const [results, setResults] = useState({ projects: [], quations: [], orders: [], employees: [], invoices: [] });
    const [resultsMobile, setResultsMobile] = useState({ projects: [], quations: [], orders: [], employees: [], invoices: [] });

    useEffect(() => {
        callFetch("chat-participation", "GET", []).then((res) => {
            // console.log(res.data);
            setParticipations(res.contacts);
        });

        callFetch("unread-messages", "GET", []).then((res) => {
            // console.log(res.unread_message);
            setunreadMessage(res.unread_message);
        });

        callFetch("topnav-notifications", "GET", []).then((res) => {
            // console.log(res.data);
            setNotifications(res.data);
            setUnreadNotification(res.unread_notifications);
        });

    }, []);

    const addToRecentSearch = (id, realText, type, icon, url) => {
        var formData = new FormData();
        formData.id = id;
        formData.real_text = realText;
        formData.type = type;
        formData.icon = icon;
        formData.text = searchKey;
        callFetch("post-recent-search", "POST", formData).then((res) => {
            console.log(res);
            window.location.href = '/' + url + '/' + id;
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            callFetch("unread-messages", "GET", []).then((res) => {
                // console.log(res.unread_message);
                setunreadMessage(res.unread_message);
            });

            callFetch("topnav-notifications", "GET", []).then((res) => {
                // console.log(res.data);
                setNotifications(res.data);
                setUnreadNotification(res.unread_notifications);
            });
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    //   useEffect(() => {
    //     const interval = setInterval(() => { 
    //         callFetch("unread-messages", "GET", []).then((res) => {
    //             // console.log(res.unread_message);
    //             setunreadMessage(res.unread_message);
    //         });   
    //     }, 240000); 
    //     return () => clearInterval(interval);
    //   },[]);

    useEffect(() => {
        setShowNotFound(false);
        if (searchKey.length > 0 || searchKeyMobile.length > 0) {
            const searchTerm = searchKey.length > 0 ? searchKey : searchKeyMobile;
            setQuery(searchTerm);
            console.log(searchTerm.trim());
            if (searchTerm.trim().length > 2) {
                setShowClose(false);
                setShowCloseMobile(false);
                setLoading(true);
                setShowNotFound(false);
                setLoadingMobile(true);
                try {
                    callFetch('global/search/' + searchTerm, "GET", []).then((res) => {
                        if (res.status !== 401) {
                            setResults(res.allData);
                            setResultsMobile(res.allData);

                            setLoading(false);
                            setLoadingMobile(false);

                            setShowClose(true);
                            setShowCloseMobile(true);

                            setSearchData(res.data.data);
                            setSearchDataMobile(res.data.data);
                            if (res.data.data.length == 0) {
                                console.log('truenotfound')
                                setShowNotFound(true);
                                setMenu(false);
                                setMenuMobile(false);
                            } else {
                                setMenu(true);
                                setMenuMobile(true);
                            }

                        } else {
                            setSearchData([]);
                            setSearchDataMobile([]);
                            setMenu(false);
                            setShowNotFound(false)
                            setMenuMobile(false);
                            setShowNotFound(false);
                        }

                    })
                } catch (error) {
                    console.error('Error fetching search results:', error);
                }
            } else {
                setShowNotFound(false);
                setMenu(false);
                setMenuMobile(false);
                setResults({ projects: [], quations: [], orders: [], employees: [], invoices: [] });
            }
        } else {
            setSearchKey('')
            setSearchKeyMobile('')
            setMenu(false)
            setShowClose(false)
            setShowCloseMobile(false)
            setMenuMobile(false);
            setSearchData([])
        }
    }, [searchKey, searchKeyMobile])



    const handleResponsive = () => {
        setCustomClass('user-chat-show')
    }

    const targetRef = useRef(null);
    const targetRefMobile = useRef(null);

    useEffect(() => {

        const handleClickOutsideMobile = (event) => {
            //console.log('clicked');
            if (targetRefMobile.current && targetRefMobile.current.contains(event.target)) {
                //console.log('mobiletrue');
            } else {
                //console.log('mobilefalse');
                setMenuMobile(false);
                setShowNotFound(false);
                setShowCloseMobile(false);
                setSearchKeyMobile('');
                setSearchDataMobile([]);
                setResultsMobile({ projects: [], quations: [], orders: [], employees: [], invoices: [] });
            }
        };

        const handleClickOutside = (event) => {
            //console.log('clicked');
            if (targetRef.current && targetRef.current.contains(event.target)) {
                console.log('menudesktoptrue');
            } else {
                //console.log('menudesktopfalse');
                setMenu(false);
                setShowClose(false);
                setSearchKey('');
                setShowNotFound(false);
                setSearchData([]);
                setResults({ projects: [], quations: [], orders: [], employees: [], invoices: [] });
            }
        };

        document.addEventListener('click', handleClickOutside);
        document.addEventListener('click', handleClickOutsideMobile);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('click', handleClickOutsideMobile);
        };


    }, []);


    const seenMessage = () => {
        callFetch("seen-messages", "GET", []).then((res) => {
            // console.log(res.unread_message);
            setunreadMessage(res.unread_message);
        });

        callFetch("unread-messages", "GET", []).then((res) => {
            // console.log(res.unread_message);
            setunreadMessage(res.unread_message);
        });
    }

    const seenNotification = () => {
        callFetch("seen-notification", "GET", []).then((res) => {
            setUnreadNotification(0);
        });
    }

    const lang = Cookies.get('lang') ? JSON.parse(Cookies.get('lang')) : { flag: 'de', lang: 'de', name: 'Deutsch' };
    const [activeLanguage, setActiveLanguage] = useState(lang);
    const { i18n } = useTranslation();

    function handleLanguageChange(e, l) {
        e.preventDefault();


        callFetch("lang/change/" + l.lang, "GET", []).then((res) => {
            console.log(res.message);
        });

        setActiveLanguage(l);
        i18n.changeLanguage(l.lang);
        Cookies.set('lang', JSON.stringify(l));
    }

    function doSignout(e) {
        e.preventDefault();

        callFetch('signout', 'POST', [], null).then(res => {
            Cookies.remove('user');
            Cookies.remove('token');
            window.location.href = process.env.REACT_APP_FRONTEND_URL;
        });
    }

    return (
        <>
            {/* Only for mobile*/}
            <div className="container-fluid mobile-topbar mt-3 d-none">
                <div className="search-area position-relative" ref={targetRefMobile}>
                    <div className="position-relative" >
                        <i className="fa fa-search search-icon"></i>
                        <input
                            autocomplete="off"
                            id="searchInput"
                            className="form-control header-search-field"
                            placeholder={t("Search...")}
                            value={searchKeyMobile ? searchKeyMobile : ''}
                            onChange={(e) => {
                                setSearchKeyMobile(e.target.value)
                            }}
                        />
                        {showCloseMobile ? <i style={{ color: '#208dfc' }} className="fa fa-close search-input-clear" onClick={() => { setShowNotFound(false); setSearchKeyMobile(''); setMenuMobile(false); setShowCloseMobile(false); setSearchDataMobile([]) }}></i> : ''}
                        {loadingMobile ? <i style={{ color: '#208dfc' }} className="fa fa-circle-o-notch fa-spin search-input-clear"></i> : ''}
                        {
                            menuMobile == true ? <div className="card position-absolute p-0 m-0 me-10 top-100 w-100 mt-1 border-radius-475rem">
                                {
                                    menuMobile && <div className="card-body m-0 search-result-box">

                                        <div className="wrapper-search">


                                            {resultsMobile.projects?.data?.length > 0 && (
                                                <>
                                                    <p className="search-category-title">Projects</p>
                                                    <ul>
                                                        {resultsMobile.projects?.data?.map((project) => {
                                                            const phoneNumberDisplay = project?.telefonnummer && project?.telefonnummer != '' ? project?.telefonnummer : <span style={{ fontSize: '0.75rem', fontStyle: 'italic' }}>{t('Keine Telefonnumer')}</span>;
                                                            return (<div class="d-flex align-items-center mb-3 search-box-item" onClick={() => { setSearchKey(''); addToRecentSearch(project?.project_id, project?.salse_person?.name, 'project', 'fa-solid fa-briefcase', 'projects') }}>
                                                                <div class="symbol symbol-40px me-3">
                                                                    <span class="symbol-label">
                                                                        <i class="fa-solid fa-briefcase text-primary">  </i>
                                                                    </span>
                                                                </div>
                                                                <div class="d-flex flex-column">
                                                                    <a onClick={() => { setSearchKey(''); addToRecentSearch(project?.project_id, project?.salse_person?.name, 'project', 'fa-solid fa-briefcase') }} href={'/projects/' + project?.project_id} class="item-text">#{project?.project_id} - {project?.vorname + ' - '} {phoneNumberDisplay}</a>
                                                                    <span class="item-sub-text">{project?.salse_person?.name} {project?.street + ', ' + project?.plz + ', ' + project?.ort}</span>
                                                                </div>
                                                            </div>)
                                                        })}
                                                    </ul>
                                                </>
                                            )}

                                            {resultsMobile.quations?.data?.length > 0 && (
                                                <>
                                                    <p className="search-category-title">Quations</p>
                                                    <ul>
                                                        {resultsMobile.quations?.data?.map((quation) => (
                                                            <div class="d-flex align-items-center mb-3 search-box-item" onClick={() => { setSearchKeyMobile(''); addToRecentSearch(quation?.quation_id, quation?.user?.name, 'quation', 'fa-solid fa-bag-shopping', 'quations') }}>
                                                                <div class="symbol symbol-40px me-3">
                                                                    <span class="symbol-label">
                                                                        <i class="fa-solid fa-bag-shopping text-primary">  </i>
                                                                    </span>
                                                                </div>
                                                                <div class="d-flex flex-column">
                                                                    <a onClick={() => { setSearchKeyMobile(''); addToRecentSearch(quation?.quation_id, quation?.user?.name, 'quation', 'fa-solid fa-bag-shopping') }} href={'/quations/' + quation?.quation_id} class="item-text">{quation?.user?.name}</a>
                                                                    <span class="item-sub-text">#{quation?.quation_id}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}

                                            {resultsMobile.orders?.data?.length > 0 && (
                                                <>
                                                    <p className="search-category-title">Orders</p>
                                                    <ul>
                                                        {resultsMobile.orders?.data?.map((order) => (
                                                            <div class="d-flex align-items-center mb-3 search-box-item" onClick={() => { setSearchKeyMobile(''); addToRecentSearch(order?.order_id, order?.user?.name, 'order', 'fa-solid fa-bag-shopping', 'orders') }}>
                                                                <div class="symbol symbol-40px me-3">
                                                                    <span class="symbol-label">
                                                                        <i class="fa-solid fa-bag-shopping text-primary">  </i>
                                                                    </span>
                                                                </div>
                                                                <div class="d-flex flex-column">
                                                                    <a onClick={() => { setSearchKeyMobile(''); }} href={'/orders/' + order?.order_id} class="item-text">{order?.user?.name}</a>
                                                                    <span class="item-sub-text">#{order?.order_id}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}

                                            {resultsMobile.employees?.data?.length > 0 && (
                                                <>
                                                    <p className="search-category-title">Employees</p>
                                                    <ul>
                                                        {resultsMobile.employees?.data?.map((employee) => (
                                                            <div class="d-flex align-items-center mb-3 search-box-item" onClick={() => { setSearchKeyMobile(''); addToRecentSearch(employee?.employee_id, employee?.user?.name, 'employee', 'fa-solid fa-users', 'employees') }}>
                                                                <div class="symbol symbol-40px me-3">
                                                                    <span class="symbol-label">
                                                                        <i class="fa-solid fa-users text-primary">  </i>
                                                                    </span>
                                                                </div>
                                                                <div class="d-flex flex-column">
                                                                    <a onClick={() => { setSearchKeyMobile(''); }} href={'/employees/' + employee?.employee_id} class="item-text">{employee?.user?.name}</a>
                                                                    <span class="item-sub-text">#{employee?.employee_id}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}

                                            {resultsMobile.invoices?.data?.length > 0 && (
                                                <>
                                                    <p className="search-category-title">Invoices</p>
                                                    <ul>
                                                        {resultsMobile.invoices?.data?.map((invoice) => (
                                                            <div class="d-flex align-items-center mb-3 search-box-item" onClick={() => { setSearchKeyMobile(''); addToRecentSearch(invoice?.invoice_id, invoice?.user?.name, 'invoice', 'fa-solid fa-users', 'invoices') }}>
                                                                <div class="symbol symbol-40px me-3">
                                                                    <span class="symbol-label">
                                                                        <i class="fa-solid fa-coins text-primary">  </i>
                                                                    </span>
                                                                </div>
                                                                <div class="d-flex flex-column">
                                                                    <a onClick={() => { setSearchKeyMobile(''); }} href={'/invoices/' + invoice?.invoice_id} class="item-text">{invoice?.user?.name}</a>
                                                                    <span class="item-sub-text">#{invoice?.invoice_id}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}

                                        </div>
                                    </div>
                                }
                            </div> : showNotFound ? <div className=" xxx card position-absolute p-0 m-0 me-10 top-100 w-100 mt-1 border-radius-475rem" style={{ minHeight: '400px' }}> <div className="card-body m-0 search-result-box">  <div className="wrapper-search"> <p style={{ textAlign: 'center', marginBottom: '.7rem', marginTop: '130px' }}><strong>No result found</strong></p>  <p style={{ textAlign: 'center' }}>Please try again with a different query</p></div>  </div> </div> : ''
                        }


                    </div>
                </div>
            </div>{/* End Only for mobile*/}

            <nav className="navbar navbar-main navbar-expand-lg mt-4 top-1 px-0 mx-4 border-radius-xl position-sticky blur shadow-blur left-auto z-index-sticky" id="navbarBlur" navbar-scroll="true">
                <div className="container-fluid py-1 px-3">

                    <Breadcrumbs icon="Home" title={route[route.length - 1]} route={route} light={light} />

                    <div className="collapse navbar-collapse" id="navbar">
                        <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                            <div className="me-3 position-relative" ref={targetRef}>
                                <i className="fa fa-search search-icon"></i>
                                <input
                                    autocomplete="off"
                                    id="searchInput"
                                    className="form-control header-search-field"
                                    placeholder={t("Search...")}
                                    value={searchKey ? searchKey : ''}
                                    onChange={(e) => {
                                        setSearchKey(e.target.value)
                                    }}
                                />
                                {showClose ? <i className="fa fa-close search-input-clear" style={{ color: '#208dfc' }} onClick={() => { setShowNotFound(false); setSearchKey(''); setShowClose(false); setSearchDataMobile([]); setMenu(false) }}></i> : ''}
                                {loading ? <i className="fa fa-circle-o-notch fa-spin search-input-clear" style={{ color: '#208dfc' }}></i> : ''}
                                {
                                    menu == true ? <div className="card position-absolute p-0 m-0 me-10 top-100 w-100 mt-1 border-radius-475rem" style={{ minHeight: '400px' }}>
                                        {

                                            menu && <div className="card-body m-0 search-result-box">


                                                <div className="wrapper-search">


                                                    {results.projects?.data?.length > 0 && (
                                                        <>
                                                            <p className="search-category-title">Projects</p>
                                                            <ul>
                                                                {results.projects?.data?.map((project) => {
                                                                    const phoneNumberDisplay = project?.telefonnummer && project?.telefonnummer != '' ? project?.telefonnummer : <span style={{ fontSize: '0.75rem', fontStyle: 'italic' }}>{t('Keine Telefonnumer')}</span>;
                                                                    return (<div class="d-flex align-items-center mb-3 search-box-item" onClick={() => { setSearchKey(''); addToRecentSearch(project?.project_id, project?.salse_person?.name, 'project', 'fa-solid fa-briefcase', 'projects') }}>
                                                                        <div class="symbol symbol-40px me-3">
                                                                            <span class="symbol-label">
                                                                                <i class="fa-solid fa-briefcase text-primary">  </i>
                                                                            </span>
                                                                        </div>
                                                                        <div class="d-flex flex-column">
                                                                            <a onClick={() => { setSearchKey(''); addToRecentSearch(project?.project_id, project?.salse_person?.name, 'project', 'fa-solid fa-briefcase') }} href={'/projects/' + project?.project_id} class="item-text">#{project?.project_id} - {project?.vorname + ' - '} {phoneNumberDisplay}</a>
                                                                            <span class="item-sub-text">{project?.salse_person?.name} {project?.street + ', ' + project?.plz + ', ' + project?.ort}</span>
                                                                        </div>
                                                                    </div>)
                                                                })}
                                                            </ul>
                                                        </>
                                                    )}

                                                    {results.quations?.data?.length > 0 && (
                                                        <>
                                                            <p className="search-category-title">Quations</p>
                                                            <ul>
                                                                {results.quations?.data?.map((quation) => (
                                                                    <div class="d-flex align-items-center mb-3 search-box-item" onClick={() => { setSearchKey(''); addToRecentSearch(quation?.quation_id, quation?.user?.name, 'quation', 'fa-solid fa-bag-shopping', 'quations') }}>
                                                                        <div class="symbol symbol-40px me-3">
                                                                            <span class="symbol-label">
                                                                                <i class="fa-solid fa-bag-shopping text-primary">  </i>
                                                                            </span>
                                                                        </div>
                                                                        <div class="d-flex flex-column">
                                                                            <a onClick={() => { setSearchKey(''); addToRecentSearch(quation?.quation_id, quation?.user?.name, 'quation', 'fa-solid fa-bag-shopping') }} href={'/quations/' + quation?.quation_id} class="item-text">{quation?.user?.name}</a>
                                                                            <span class="item-sub-text">#{quation?.quation_id}</span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </ul>
                                                        </>
                                                    )}

                                                    {results.orders?.data?.length > 0 && (
                                                        <>
                                                            <p className="search-category-title">Orders</p>
                                                            <ul>
                                                                {results.orders?.data?.map((order) => (
                                                                    <div class="d-flex align-items-center mb-3 search-box-item" onClick={() => { setSearchKey(''); addToRecentSearch(order?.order_id, order?.user?.name, 'order', 'fa-solid fa-bag-shopping', 'orders') }}>
                                                                        <div class="symbol symbol-40px me-3">
                                                                            <span class="symbol-label">
                                                                                <i class="fa-solid fa-bag-shopping text-primary">  </i>
                                                                            </span>
                                                                        </div>
                                                                        <div class="d-flex flex-column">
                                                                            <a onClick={() => { setSearchKey(''); }} href={'/orders/' + order?.order_id} class="item-text">{order?.user?.name}</a>
                                                                            <span class="item-sub-text">#{order?.order_id}</span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </ul>
                                                        </>
                                                    )}

                                                    {results.employees?.data?.length > 0 && (
                                                        <>
                                                            <p className="search-category-title">Employees</p>
                                                            <ul>
                                                                {results.employees?.data?.map((employee) => (
                                                                    <div class="d-flex align-items-center mb-3 search-box-item" onClick={() => { setSearchKey(''); addToRecentSearch(employee?.employee_id, employee?.user?.name, 'employee', 'fa-solid fa-users', 'employees') }}>
                                                                        <div class="symbol symbol-40px me-3">
                                                                            <span class="symbol-label">
                                                                                <i class="fa-solid fa-users text-primary">  </i>
                                                                            </span>
                                                                        </div>
                                                                        <div class="d-flex flex-column">
                                                                            <a onClick={() => { setSearchKey(''); }} href={'/employees/' + employee?.employee_id} class="item-text">{employee?.user?.name}</a>
                                                                            <span class="item-sub-text">#{employee?.employee_id}</span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </ul>
                                                        </>
                                                    )}

                                                    {results.invoices?.data?.length > 0 && (
                                                        <>
                                                            <p className="search-category-title">Invoices</p>
                                                            <ul>
                                                                {results.invoices?.data?.map((invoice) => (
                                                                    <div class="d-flex align-items-center mb-3 search-box-item" onClick={() => { setSearchKey(''); addToRecentSearch(invoice?.invoice_id, invoice?.user?.name, 'invoice', 'fa-solid fa-users', 'invoices') }}>
                                                                        <div class="symbol symbol-40px me-3">
                                                                            <span class="symbol-label">
                                                                                <i class="fa-solid fa-coins text-primary">  </i>
                                                                            </span>
                                                                        </div>
                                                                        <div class="d-flex flex-column">
                                                                            <a onClick={() => { setSearchKey(''); }} href={'/invoices/' + invoice?.invoice_id} class="item-text">{invoice?.user?.name}</a>
                                                                            <span class="item-sub-text">#{invoice?.invoice_id}</span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </ul>
                                                        </>
                                                    )}

                                                </div>
                                            </div>
                                        }
                                    </div> : showNotFound ? <div className=" xxx card position-absolute p-0 m-0 me-10 top-100 w-100 mt-1 border-radius-475rem" style={{ minHeight: '400px' }}> <div className="card-body m-0 search-result-box">  <div className="wrapper-search"> <p style={{ textAlign: 'center', marginBottom: '.7rem', marginTop: '130px' }}><strong>No result found</strong></p>  <p style={{ textAlign: 'center' }}>Please try again with a different query</p></div>  </div> </div> : ''
                                }


                            </div>
                        </div>

                        <ul className="navbar-nav justify-content-end">
                            <li className="nav-item d-xl-none mx-3 d-flex align-items-center mobile-menu-icon" style={{ position: 'absolute', left: '-0px', top: '28px' }}>
                                <a className="nav-link text-body p-0" id="iconNavbarSidenav">
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item dropdown pe-2 d-flex align-items-center" style={{ marginRight: "15px" }}>
                                <a href="notification" className="nav-link text-body p-0 mt-1" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-solid fa-envelope top-nav-icon cursor-pointer">{unreadMessage > 0 && <sup className="badge badge-danger" style={{
                                        fontSize: '13px', padding: '3px',
                                        height: '19px',
                                        width: '19px',
                                        position: 'relative',
                                        top: '3px',
                                        left: '-5px',
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                        color: '#fff',
                                    }}>{unreadMessage}</sup>}</i>
                                </a>
                                <ul className="dropdown-menu res-dropdown-menu dropdown-menu-end border-0 me-sm-n4" aria-labelledby="dropdownMenuButton">
                                    <div class="overflow-hidden z-50 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg shadow-gray-300 block" id="notification-dropdown" data-popper-placement="bottom">
                                        <div class="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50">
                                            Messages
                                        </div>
                                        <div className="scroll-cs" style={{ maxHeight: '415px', width: '350px' }}>

                                            {participations ? participations.map((participation) => (
                                                <>
                                                    <a to={'/chat/' + participation.id} class="d-flex py-3 px-4 border-b hover:bg-gray-100">
                                                        <div class="flex-shrink-0">
                                                            <img style={{ border: '0.125rem solid rgb(4, 75, 178)' }} class="w-11 h-11 rounded-full" src={user.photo ? Cookies.get('backend_url') + user.photo : '/assets/img/placeholder.png'} alt="Jese image" />
                                                            {/*
                                                            <div class="d-flex position-relative justify-center-c items-center-c ml-6 -mt-5 wc-5 h-5 bg-fuchsia-600 rounded-full border border-white ">
                                                                {icon}
                                                            </div>
                                                            */}
                                                        </div>
                                                        <div class="plc-3 wc-full">
                                                            <div class="text-gray-500 font-normal text-sm mb-1.5">{participation.name} <span class="text-gray-900"><LastMessage userId={participation.id}></LastMessage></span></div>
                                                            {/* <div class="text-gray-500 font-normal text-sm mb-1.5">New message from <span class="font-semibold text-gray-900">Bonnie Green</span>: "Hey, what's up? All set for the presentation?"</div> */}
                                                            <div class="text-xs text-primary"> <Moment fromNow>{participation.created_at}</Moment></div>
                                                        </div>
                                                    </a>
                                                </>
                                            )) : <></>}
                                        </div>
                                    </div>
                                </ul>
                            </li>

                            <li className="nav-item dropdown pe-2 d-flex align-items-center" style={{ marginRight: "8px" }}>
                                <a href="notification" className="nav-link text-body p-0 mt-1" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-bell cursor-pointer top-nav-icon">
                                        {unreadNotification > 0 && <sup className="badge badge-danger" style={{
                                            fontSize: '13px', padding: '3px',
                                            height: '19px',
                                            width: '19px',
                                            position: 'relative',
                                            top: '3px',
                                            left: '-5px',
                                            backgroundColor: 'red',
                                            borderRadius: '50%',
                                            color: '#fff',
                                        }}>{unreadNotification}</sup>}
                                    </i>
                                </a>
                                <ul className="dropdown-menu res-dropdown-menu dropdown-menu-end border-0 me-sm-n4" aria-labelledby="dropdownMenuButton">
                                    <div class="overflow-hidden z-50 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg shadow-gray-300 block" id="notification-dropdown" data-popper-placement="bottom">
                                        <div class="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50">
                                            Notifications
                                        </div>
                                        <div className="scroll-cs" style={{ maxHeight: '415px', width: '350px' }}>
                                            {notifications?.data ? notifications?.data.map((notification) => {
                                                const title = notification.title;
                                                const splitTitle = title.split('#');
                                                var icon = '';
                                                if (
                                                    notification.type === 'project' ||
                                                    notification.type === 'client_sign_order_overview'
                                                ) {
                                                    icon = <i class="fa-solid fa-briefcase text-primary" style={{ fontSize: "12px" }}>  </i>;
                                                } else if (notification.type == 'quation') {
                                                    icon = <i class="fa-solid fa-bag-shopping text-primary" style={{ fontSize: "12px" }}>  </i>;
                                                } else if (notification.type == 'order') {
                                                    icon = <i class="fa-solid fa-bag-shopping text-primary" style={{ fontSize: "12px" }}>  </i>;
                                                }
                                                return <a href={'/projects/' + notification.task_id} class="d-flex py-3 px-3 border-b hover:bg-gray-100">
                                                    {notification.status === '0' ? (
                                                        <>
                                                            <SoftBox display="flex" alignItems="center" className="pe-2">
                                                                <SoftBox
                                                                    component="i"
                                                                    display="inline-block"
                                                                    width={"0.5rem"}
                                                                    height={"0.5rem"}
                                                                    borderRadius="50%"
                                                                    bgColor={'#CB0C9F'}
                                                                    sx={{
                                                                        //marginBottom: '14px'
                                                                    }}
                                                                />
                                                            </SoftBox>
                                                        </>
                                                    ) : <div className="p-0 pe-1" style={{ width: '12px'}}></div>}
                                                    <div class="flex-shrink-0">
                                                        <img style={{ border: '0.125rem solid rgb(4, 75, 178)' }} class="w-11 h-11 rounded-full" src={notification?.photo?.length > 0 ? Cookies.get('backend_url') + 'storage/avatar/' + notification.photo : '/assets/img/placeholder.png'} alt="Jese image" />
                                                        <div class="d-flex position-relative justify-center-c items-center-c ml-6 -mt-5 wc-5 h-5 bg-fuchsia-600 rounded-full border border-white ">
                                                            {icon}
                                                        </div>
                                                    </div>
                                                    <div class="plc-3 wc-full">
                                                        <div class="text-gray-500 font-normal text-sm mb-1.5">
                                                            {notification.name ? (
                                                                <>
                                                                    <span class="font-semibold text-gray-900">{notification.name}</span> {notification.title} <span class="font-semibold text-gray-900">{notification.task_id}</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span class="font-semibold text-gray-900">{notification.task_id}</span> {notification.title}
                                                                </>
                                                            )}


                                                        </div>
                                                        {/* <div class="text-gray-500 font-normal text-sm mb-1.5">New message from <span class="font-semibold text-gray-900">Bonnie Green</span>: "Hey, what's up? All set for the presentation?"</div> */}
                                                        <div class="text-xs text-primary"> <Moment fromNow>{notification.created_at}</Moment></div>
                                                    </div>
                                                </a>
                                            }) : <></>}
                                        </div>
                                    </div>
                                </ul>
                            </li>
                            <li className="nav-item d-flex align-items-center">
                                <div className="ms-md-auto pe-md-3 d-flex">
                                    <div className="dropdown">
                                        <a href="flags" className="m-0 nav-link text-body font-weight-bold px-0" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="/assets/img/placeholder.png" alt="" style={{ height: "32px", width: "32px", borderRadius: '50%', border: '0.125rem solid rgb(4, 75, 178)' }} />
                                        </a>
                                        <ul className="dropdown-menu res-dropdown-menu dropdown-menu-end border-0 shadow" aria-labelledby="dropdownMenuButton1">
                                            <div style={{ padding: "0px 15px" }}>
                                                <li className="">{user && user.name ? user.name : ''}</li>
                                                <li className="">{user && user.email ? user.email : ''}</li>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                            <li><a href="#0" className="dropdown-item">{t('Dashboard')}</a></li>
                                            {Cookies.get('user') && JSON.parse(Cookies.get('user')).rolename == 'Call Center' || JSON.parse(Cookies.get('user')).rolename == 'DC Technician' ? (
                                                <>

                                                </>
                                            ) : <>
                                                <li><NavLink to="/profile/settings" className="dropdown-item">{t('Settings')}</NavLink></li>

                                            </>}
                                            <li><a href="#0" onClick={(e) => doSignout(e)} className="dropdown-item">{t('Sign Out')}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default TopNav;
