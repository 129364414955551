import React, { useEffect, useState } from 'react'
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import DataTable from 'react-data-table-component';
import callFetch from 'helpers/callFetch';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import deleteAlert from 'helpers/deleteAlert';
import Cookies from 'js-cookie';
import DefaultCell from './components/DefaultCell';
import ProductCell from './components/ProductCell';
import DataTableHeadCell from './components/DataTableHeadCell/DataTableHeadCell';

const WireHouseBaseArticle = () => {
    const params = useParams();
    const [data,setData] = useState({});
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);



    const tableHeadings = [
        {
            name: t('Produktnr.'),
            selector: row => <DefaultCell>{row?.product_id}</DefaultCell>,
        },
        {
            name: t('Produktbeschreibung'),
            width: '500px',
            selector: row => (
                // <DefaultCell><NavLink to={'/supply-chain/wire-house/' + row.id + '/details'}>{row.article_nr}</NavLink></DefaultCell>
                true ?
                <div className="py-2">
                    <ProductCell image={''} name={row?.product_type == 'Art' ? row?.article_name : row?.product_type == 'MDL' ? row?.mdlname : row?.product_type == 'SPC' ? row?.spcname : row?.product_type == 'WR' ? row?.wechname : ''} orders={200.30}/>
                </div> 
                :
                <ProductCell image="'" name={row?.product_id} orders={200.30}/>
                
            ),
            sortable: true,
        },        
        {
            name: t('Lagerbestand'),
            selector: row => <DefaultCell>{row?.total_quantity}</DefaultCell>,
        },
        {
            name: t('Produktwert'),
            cell: row => "---",
        },
        {
            name: t('Produktkategories'),
            cell: row => row?.article_category,
        }
    ];

    useEffect(() => {
        callFetch("lager/"+params?.id+'?page='+pageNumber, "GET", []).then((res) => {
            // setData(res.data)
            //console.log(res.articles)
            setTableData(res.articles);
        });
    }, [pageNumber, refresh, params?.id]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;
        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

  return (
    <Grid container spacing={3}>
        <Grid item xs={12}>
        <Card>
            <SoftBox pt={3} px={3}>
            <SoftTypography variant="h6" fontWeight="medium">
                {
                    data && data?.lager_name
                }
            </SoftTypography>
            </SoftBox>
            <SoftBox py={1}>
                <DataTable
                    columns={tableHeadings}
                    data={tabledata?.data}
                    noDataComponent={t('There are no records to display')}
                    className='data-table'
                    pagination
                    highlightOnHover
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationServer
                    paginationTotalRows={tabledata?.total}
                    onChangePage={handlePageChange}
                    paginationComponent={BootyPagination}
                />
            </SoftBox>
        </Card>
        </Grid>
    </Grid>
  )
}

export default WireHouseBaseArticle